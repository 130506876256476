import { createColumnHelper } from "@tanstack/react-table";
import Link from "next/link";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ASSESSMENT_ROUND_WARNING_DAY_THRESHOLD } from "../../../constants";
import { useMeta } from "../../../context/MetaContext";
import { formatDateDistance } from "../../../helpers/datetime";
import { useGetAssessmentRounds } from "../../../services/assessmentRounds/useGetAssessmentRounds";
import { useGetDashboardProgress } from "../../../services/dashboard/useGetDashboardProgress";
import { DashboardProgressItem } from "../../../types/Dashboard";
import { Block } from "../../atoms/Block";
import { BlockTitle } from "../../atoms/BlockTitle";
import { Button } from "../../atoms/Button";
import { Progress } from "../../atoms/Progress";
import { SpinnerSection } from "../../atoms/Spinner/SpinnerSection";
import { DataTable } from "../../molecules/DataTable/DataTable";
import { EventList } from "../../molecules/EventList";
import { EventListItem } from "../../molecules/EventListItem";
import styles from "./StudentDashboard.module.scss";
import { StudentDashboardProps } from "./StudentDashboard.types";

const columnHelper = createColumnHelper<DashboardProgressItem>();

export const StudentDashboard: FC<StudentDashboardProps> = ({}) => {
  const { t } = useTranslation();
  const { education_levels } = useMeta();
  const { data, error, isLoading, isError } = useGetDashboardProgress();
  const { data: assessmentRounds } = useGetAssessmentRounds();
  const hasData = data?.length || 0 > 0;
  const assessmentRoundThresholdDate = new Date(
    new Date().setDate(new Date().getDate() + ASSESSMENT_ROUND_WARNING_DAY_THRESHOLD)
  );

  return (
    <div className={styles.StudentDashboard}>
      {/* <div className={styles.StudentDashboardStatistics}>
        <Statistics>
          <Statistic
            title="Behaalde skills"
            score="21"
            trend={<StatisticTrend type="positive" text="+10 in afgelopen 7 dagen" />}
          />
          <Statistic
            title="Te laat behaalde rubrics"
            score="3"
            trend={<StatisticTrend type="negative" text="+1 in afgelopen 7 dagen" />}
          />
        </Statistics>
      </div> */}

      <Block className={styles.StudentDashboardProgressTable}>
        <BlockTitle>{t("my_progress")}</BlockTitle>
        {isLoading && <SpinnerSection />}
        {!isLoading && !error && !hasData && <span>{t("empty_coures")}</span>}
        {!isLoading && hasData && (
          <DataTable<DashboardProgressItem>
            className={styles.StudentDashboardProgressTable}
            isLoading={isLoading}
            isError={isError}
            data={data || []}
            features={{
              state: {
                columnVisibility: {
                  skills: false,
                  skills_rated: false,
                  education_level_level: false,
                },
              },
            }}
            columns={[
              columnHelper.accessor((data) => data.skills.toString(), {
                id: "skills",
              }),
              columnHelper.accessor((data) => data.skills_rated.toString(), {
                id: "skills_rated",
              }),
              columnHelper.accessor((data) => data.education_level_level.toString(), {
                id: "education_level_level",
              }),
              columnHelper.accessor("subject_name", { header: t("subject") }),
              columnHelper.accessor((data) => data.skills.toString(), {
                id: "progress",
                header: t("progress"),
                size: 120,
                cell: ({ row }) => {
                  const educationLevel = education_levels?.find(
                    (l) => l.level === Number(row.getValue("education_level_level"))
                  );

                  return (
                    <div style={{ maxWidth: 120 }}>
                      <Progress
                        value={
                          row.getValue<number>("skills") > 0
                            ? Math.round((row.getValue<number>("skills_rated") / row.getValue<number>("skills")) * 100)
                            : 0
                        }
                        color={`var(--bg-${educationLevel?.label.toLowerCase()}-base)`}
                      />
                    </div>
                  );
                },
              }),
              columnHelper.accessor("last_rated", {
                header: t("last_rated"),
                cell: ({ row }) =>
                  row.getValue("last_rated") ? `${formatDateDistance(row.getValue("last_rated"), true)}` : "",
              }),
              columnHelper.accessor((row) => row.class_id.toString(), {
                header: " ",
                cell: ({ getValue }) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Link href={`/student/classes/${getValue()}`} passHref>
                      <a>
                        <Button>{t("view")}</Button>
                      </a>
                    </Link>
                  </div>
                ),
              }),
            ]}
          />
        )}
      </Block>

      <Block className={styles.StudentDashboardOpenRatings}>
        <BlockTitle>{t("open_ratings")}</BlockTitle>
        <EventList>
          {assessmentRounds?.data?.map((assessmentRound) => {
            let warningText = undefined;

            if (new Date(assessmentRound.valid_to).getTime() < assessmentRoundThresholdDate.getTime()) {
              warningText = t("time_left_until_deadLine", {
                timeLeft: formatDateDistance(assessmentRound.valid_to, false),
              });
            }

            return (
              <Link href={`/assessment-round/${assessmentRound.id}`} passHref key={assessmentRound.id}>
                <a>
                  <EventListItem
                    // note: student endpoint uses old subject format
                    name={assessmentRound.subject_name || ""}
                    date={assessmentRound.valid_to}
                    dateWarning={warningText}
                  />
                </a>
              </Link>
            );
          })}
          {assessmentRounds?.data?.length === 0 && <>{t("no_open_assessment_rounds")}</>}
        </EventList>
      </Block>
    </div>
  );
};
