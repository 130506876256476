import { useQuery } from "@tanstack/react-query";
import { API_ENDPOINT } from "../../constants";
import { ApiBaseResponse } from "../../types/ApiBaseResponse";
import { requestJson } from "../api";
import { dashboardKeys } from "./dashboardKeys";
import { DashboardProgressItem } from "../../types/Dashboard";

export const useGetDashboardProgress = () => {
  return useQuery(
    dashboardKeys.progress(),
    () =>
      requestJson<ApiBaseResponse<DashboardProgressItem[]>>(`${API_ENDPOINT}/app/student/dashboard/progress`).then(
        ({ data }) => data
      ),
    {
      enabled: true,
    }
  );
};
