import { useMeta } from "../../../context/MetaContext";
import { StudentDashboard } from "../../organisms/StudentDashboard";
import { TeacherDashboard } from "../../organisms/TeacherDashboard";

export const DashboardRenderer = () => {
  const { authorisation } = useMeta();
  const isStudent = authorisation.roles.some((role) => role.key === "student");

  if (!isStudent) {
    return <TeacherDashboard />;
  }

  if (isStudent) {
    return <StudentDashboard />;
  }

  return null;
};
