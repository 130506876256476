import React, { useContext } from "react";
import Link from "next/link";
import { ChevronLeft, ChevronRight } from "../../atoms/icons";
import styles from "./Pagination.module.scss";
import { PaginationContext } from "../../../context/PaginationContext";
import { PaginationButton } from "./PaginationButton";
import { ButtonVariant } from "../../atoms/Button";

export type PaginationProps = {
  onClick: (page: number) => () => void;
};

export const Pagination: React.FC<PaginationProps> = ({ onClick }) => {
  const { meta } = useContext(PaginationContext);

  if (!meta || meta.total <= meta.per_page) {
    return null;
  }
  const prevPage = Math.max(1, meta.current_page - 1);
  const nextPage = Math.min(meta.last_page ?? 10, meta.current_page + 1);

  const parseLinkPage = (link: { url: string | null; label: string; active: boolean }) => {
    let handleClick: React.MouseEventHandler<HTMLButtonElement> | undefined = undefined;
    let page = -1;

    // if the link is clickable
    if (link.url !== null) {
      const url = new URL(link.url);
      page = parseInt(url.searchParams.get("page") ?? "1", 10);
      handleClick = onClick(page);
    }

    return {
      onClick: handleClick,
      isCurrent: meta.current_page === page,
    };
  };

  return (
    <div className={styles.Pagination}>
      <nav>
        {/* Previous */}
        <PaginationButton variant={ButtonVariant.PLAIN} onClick={onClick(prevPage)} icon={<ChevronLeft />} />

        {/* Pages, slice first and last because they are for the previous and next */}
        {meta.links.slice(1, meta.links.length - 1).map((link) => {
          const { onClick, isCurrent } = parseLinkPage(link);
          return (
            <PaginationButton
              variant={isCurrent ? ButtonVariant.PRIMARY : onClick ? ButtonVariant.GHOST : ButtonVariant.PLAIN}
              key={link.url}
              onClick={onClick}
              isCurrent={isCurrent}
            >
              {link.label}
            </PaginationButton>
          );
        })}

        {/* Next */}
        <PaginationButton variant={ButtonVariant.PLAIN} onClick={onClick(nextPage)} icon={<ChevronRight />} />
      </nav>
    </div>
  );
};
