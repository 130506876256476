import { useTranslation } from "react-i18next";
import React, { FC } from "react";
import { AuthenticatedLayout } from "../../layouts/AuthenticatedLayout";
import { DashboardProps } from "./Dashboard.types";
import { DashboardRenderer } from "./DashboardRenderer";
import { PageHeader } from "../../molecules/PageHeader";

export const Dashboard: FC<DashboardProps> = () => {
  const { t } = useTranslation();

  return (
    <AuthenticatedLayout>
      <PageHeader title={t("dashboard")} data-testid="dashboard" />
      <DashboardRenderer />
    </AuthenticatedLayout>
  );
};
