import { useRouter } from "next/router";
import React, { PropsWithChildren, useEffect } from "react";
import { useAuthenticationStatus } from "../../../auth/AuthenticationContext";

export const AuthenticatedGuard: React.FC<PropsWithChildren> = ({ children }) => {
  const status = useAuthenticationStatus();
  const router = useRouter();

  useEffect(() => {
    if (status === "unauthenticated") {
      router.push("/login");
    }
  }, [status, router]);

  if (status === "initializing" || status === "unauthenticated") {
    return null;
  }

  return <div>{children}</div>;
};
