import React from "react";
import { Button } from "../../atoms/Button";
import { ButtonProps, ButtonSize } from "../../atoms/Button/Button.types";

interface Props extends Omit<ButtonProps, "children"> {
  isCurrent?: boolean;
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const PaginationButton: React.FC<Props> = ({ children, isCurrent, onClick, ...props }) => {
  return (
    <Button
      size={ButtonSize.XSMALL}
      onClick={onClick}
      aria-current={isCurrent ? "page" : undefined}
      style={{
        cursor: onClick ? "pointer" : "default",
        alignItems: "center",
        justifyContent: "center",
      }}
      {...props}
    >
      {children}
    </Button>
  );
};
