import { useQuery } from "@tanstack/react-query";
import { ColumnFiltersState } from "@tanstack/react-table";
import { useRouter } from "next/router";
import { useContext } from "react";
import { API_ENDPOINT } from "../../constants";
import { useMeta } from "../../context/MetaContext";
import { PaginationContext } from "../../context/PaginationContext";
import { ApiPaginationResponse } from "../../types/ApiPaginationResponse";
import { AssessmentRound } from "../../types/AssessmentRound";
import { requestJson } from "../api";
import { assessmentRoundsKeys } from "./assessmentRoundsKeys";
import { useAuthorization } from "../../context/AuthorizationContext";

export const useGetAssessmentRounds = (columns: ColumnFiltersState = []) => {
  const { meta } = useContext(PaginationContext);
  const { authorisation } = useMeta();
  const { hasPermission } = useAuthorization();
  const role = authorisation.roles.some((role) => role.key === "student") ? "student" : "teacher";
  const teacherCanView = hasPermission("assessment_round_index", { strategy: "some" });
  const router = useRouter();

  // IS Paginated
  let url = `${API_ENDPOINT}/app/assessment-rounds`;

  if (role === "student") {
    // NOT paginated
    url = `${API_ENDPOINT}/app/student/assessment-rounds`;
  }

  const page = Number(router.query.page) || meta.current_page;

  return useQuery(
    assessmentRoundsKeys.index(role, columns, page),
    (args) => {
      const filterParams = columns.reduce((acc, column) => {
        return {
          ...acc,
          [`filter[${column.id}]`]: ["name", "expired"].includes(column.id) ? column.value : [column.value],
        };
      }, {});

      return requestJson<ApiPaginationResponse<AssessmentRound[]>>(url, {
        queryParams: {
          page: page,
          ...filterParams,
          include: "cohort",
        },
      });
    },
    {
      keepPreviousData: true,
      enabled: teacherCanView || role === "student",
      placeholderData: {
        data: [],
        status: 200,
        message: "",
        links: {
          first: "",
          last: "",
          next: "",
          prev: "",
        },
        meta: {
          current_page: 1,
          last_page: 1,
          per_page: 10,
          total: 0,
          from: 0,
          to: 0,
          path: "",
          links: [],
        },
      },
    }
  );
};
