import { ColumnFiltersState } from "@tanstack/react-table";
import { AuthorizationRoleKey } from "../../types/Authorization";

const filterKeys = (cols: ColumnFiltersState) => {
  if (cols.length === 0) return "noFilters";

  return cols.flatMap((c) => `${c.id}=${c.value}`).join(",");
};

export const assessmentRoundsKeys = {
  studentAssessmentRoundSkills: (id: string) => ["student", "assessmentRound", id, "skills"] as const,
  studentAssessmentRoundSkillRates: (id: string) => ["student", "assessmentRound", id, "skillRates"] as const,
  studentAssessmentRoundGoals: (id: string) => ["student", "assessmentRound", id, "goals"] as const,
  createAssessmentRound: ["assessmentRounds", "create"] as const,
  updateAssessmentRound: ["assessmentRounds", "update"] as const,
  deleteAssessmentRound: ["assessmentRounds", "delete"] as const,
  finishAssessmentRound: ["assessmentRounds", "finish"] as const,
  show: (assessmentRoundId: string, role: AuthorizationRoleKey) =>
    ["assessmentRounds", role, assessmentRoundId] as const,
  index: (role: AuthorizationRoleKey, cols: ColumnFiltersState, page: number) => [
    "assessmentRounds",
    role,
    filterKeys(cols),
    page,
  ],
};
