import { useQuery } from "@tanstack/react-query";
import { API_ENDPOINT } from "../../constants";
import { ApiBaseResponse } from "../../types/ApiBaseResponse";
import { requestJson } from "../api";
import { dashboardKeys } from "./dashboardKeys";
import { DashboardClassItem } from "../../types/Dashboard";

export const useGetDashboardClasses = () => {
  return useQuery(dashboardKeys.classes(), () =>
    requestJson<ApiBaseResponse<DashboardClassItem[]>>(`${API_ENDPOINT}/app/dashboard/classes`).then(({ data }) => data)
  );
};
