import { FC, ReactNode } from "react";
import classNames from "classnames";
import Icon from "../../atoms/icons";
import styles from "./EventListItem.module.scss";
import { formatDateFull } from "../../../helpers/datetime";

export interface EventListItemProps {
  name: string;
  date: string;
  subText?: string;
  dateWarning?: string;
  warningIcon?: ReactNode;
  className?: string;
}

export const EventListItem: FC<EventListItemProps> = ({
  name,
  date,
  dateWarning,
  warningIcon: WarningIcon,
  className,
  subText,
}) => (
  <div className={classNames([styles.EventListItem, className])}>
    <div className={styles.EventListItemBody}>
      <span className={styles.EventListItemName}>{name}</span>

      <div className={styles.EventListItemRows}>
        <div className={styles.EventListItemRow}>
          <div className={styles.EventListItemIconWrap}>
            <Icon name="calendarmonth" />
          </div>
          <span className={styles.EventListItemMeta}>{formatDateFull(date)}</span>
        </div>

        <div className={styles.EventListItemRow}>
          <div className={styles.EventListItemIconWrap}>
            <Icon name="late" />
          </div>
          {subText && <span className={styles.EventListItemMeta}>{subText}</span>}
        </div>
      </div>

      {dateWarning && (
        <div className={styles.EventListItemWarning}>
          {WarningIcon || (
            <div className={styles.RatingBlockDeadlineIcon}>
              <Icon name="late" />
            </div>
          )}
          <span>{dateWarning}</span>
        </div>
      )}
    </div>
    <div data-type="icon">
      <Icon name="chevronright" />
    </div>
  </div>
);
