import { FC } from "react";
import classNames from "classnames";
import "react-dropdown-tree-select/dist/styles.css";
import { BlockTitleProps } from "./BlockTitle.types";
import styles from "./BlockTitle.module.scss";

export const BlockTitle: FC<BlockTitleProps> = ({ children, ...rest }) => (
  <h2 {...rest} className={classNames(styles.BlockTitle, rest.className)}>
    {children}
  </h2>
);
