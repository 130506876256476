import type { NextPage } from "next";
import Head from "next/head";
import { useTranslation } from "react-i18next";
import { createPageTitle } from "../helpers/createPageTitle";
import { Dashboard } from "../components/pages/Dashboard";
import { AuthenticatedGuard } from "../components/atoms/AuthenticatedGuard/AuthenticatedGuard";

const Home: NextPage = () => {
  const { t } = useTranslation();

  return (
    <AuthenticatedGuard>
      <Head>
        <title>{createPageTitle(t, t("dashboard"))}</title>
      </Head>
      <Dashboard />
    </AuthenticatedGuard>
  );
};

export default Home;
