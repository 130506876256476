import { addDays, addYears, format, formatDistanceStrict } from "date-fns";
import { nl } from "date-fns/locale";

export const formatDate = (date: string) => {
  return Intl.DateTimeFormat("nl-NL", {
    year: "numeric",
    month: "short",
    day: "numeric",
  }).format(new Date(date));
};

export const formatTime = (date: string) => {
  return Intl.DateTimeFormat("nl-NL", {
    hour: "numeric",
    minute: "numeric",
  }).format(new Date(date));
};

export const formatDateFull = (date: string) => {
  return format(new Date(date), "dd-MM-yyyy HH:mm", { locale: nl });
};

export const formatDateDistance = (date: string, addSuffix: boolean = false) => {
  let formattedValue = "-";
  try {
    formattedValue = formatDistanceStrict(new Date(date), Date.now(), { addSuffix: addSuffix, locale: nl });
  } catch (error) {
    console.warn("Error formatting date distance", error);
  }

  return formattedValue;
};

export const isActiveOrCompletedPeriod = (date1: string, date2: string): boolean => {
  const now = new Date().getTime();

  return new Date(date1).getTime() < now;
};

export const addNumberOfDays = (date: string, numberOfDays: number): string => {
  const newDate = addDays(new Date(date), numberOfDays);
  return format(newDate, "yyyy-MM-dd"); // input[type=date] format
};

export const addNumberOfYears = (date: string, numberOfYears: number): string => {
  const newDate = addYears(new Date(date), numberOfYears);
  return format(newDate, "yyyy-MM-dd"); // input[type=date] format
};
