import React from "react";
import { ApiPaginationLinks, ApiPaginationMeta } from "../types/ApiPaginationResponse";

type PaginationContextType = {
  meta: Partial<ApiPaginationMeta> & {
    current_page: number;
    total: number;
    per_page: number;
    links: ApiPaginationMeta["links"];
  };
  links: ApiPaginationLinks;
};

type PaginationContextProviderProps = {
  value?: {
    meta: ApiPaginationMeta;
    links: ApiPaginationLinks;
  };
  children: React.ReactNode;
};

export const PaginationContextProvider: React.FC<PaginationContextProviderProps> = ({ children, value }) => {
  return (
    <PaginationContext.Provider value={value ? value : defaultPaginationContext}>{children}</PaginationContext.Provider>
  );
};

export const defaultPaginationContext: PaginationContextType = {
  meta: {
    current_page: 1,
    per_page: 0,
    total: 0,
    links: [],
  },
  links: {
    first: "#",
    last: "#",
    prev: null,
    next: null,
  },
};

export const PaginationContext = React.createContext<PaginationContextType>(defaultPaginationContext);
