import { createColumnHelper } from "@tanstack/react-table";
import Link from "next/link";
import router from "next/router";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ASSESSMENT_ROUND_WARNING_DAY_THRESHOLD } from "../../../constants";
import { useAuthorization } from "../../../context/AuthorizationContext";
import { PaginationContextProvider } from "../../../context/PaginationContext";
import { formatDateDistance } from "../../../helpers/datetime";
import { useGetAssessmentRounds } from "../../../services/assessmentRounds/useGetAssessmentRounds";
import { useGetDashboardClasses } from "../../../services/dashboard/useGetDashboardClasses";
import { DashboardClassItem } from "../../../types/Dashboard";
import { Block } from "../../atoms/Block";
import { BlockTitle } from "../../atoms/BlockTitle";
import { Button } from "../../atoms/Button";
import { SpinnerSection } from "../../atoms/Spinner/SpinnerSection";
import { DataTable } from "../../molecules/DataTable/DataTable";
import { EventList } from "../../molecules/EventList";
import { EventListItem } from "../../molecules/EventListItem";
import { Pagination } from "../../molecules/Pagination/Pagination";
import styles from "./TeacherDashboard.module.scss";
import { TeacherDashboardProps } from "./TeacherDashboard.types";

const columnHelper = createColumnHelper<DashboardClassItem>();

export const TeacherDashboard: FC<TeacherDashboardProps> = ({}) => {
  const { t } = useTranslation();
  const { hasPermission } = useAuthorization();
  const { data, error, isLoading, isError } = useGetDashboardClasses();
  const { data: assessmentRounds, refetch } = useGetAssessmentRounds();

  const canViewAssessmentRounds = hasPermission("assessment_round_index", { strategy: "some" });

  const hasData = data?.length || 0 > 0;
  const assessmentRoundThresholdDate = new Date(
    new Date().setDate(new Date().getDate() + ASSESSMENT_ROUND_WARNING_DAY_THRESHOLD)
  );

  // TODO: refactor this to a prettier solution
  const paginationData =
    assessmentRounds?.meta && assessmentRounds?.links
      ? { meta: assessmentRounds.meta, links: assessmentRounds.links }
      : undefined;

  const handlePagination = (page: number) => async () => {
    await router.push({
      pathname: router.pathname,
      query: { page },
    });
    refetch();
  };

  return (
    <div className={styles.TeacherDashboard}>
      <Block className={styles.TeacherDashboardClasses} data-testid="teacher-dashboard">
        <BlockTitle>{t("my_subject_groups")}</BlockTitle>
        {isLoading && <SpinnerSection />}
        {!isLoading && !error && !hasData && <span>{t("empty_subject_groups")}</span>}
        {!isLoading && hasData && (
          <DataTable<DashboardClassItem>
            data={data || []}
            isLoading={isLoading}
            isError={isError}
            features={{
              state: {
                columnVisibility: {
                  id: false,
                },
              },
            }}
            columns={[
              columnHelper.accessor((user) => user.id.toString(), {
                id: "id",
              }),
              columnHelper.accessor("name", { header: t("subject_group") }),
              columnHelper.accessor("subject", { header: t("subject") }),
              columnHelper.accessor("mentor", { header: t("mentor") }),
              columnHelper.accessor((group) => group.students_amount.toString(), {
                header: t("students_amount"),
              }),
              columnHelper.display({
                header: " ",
                cell: ({ row }) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Link href={`/classes/${row.getValue("id")}/list`} passHref>
                      <a>
                        <Button data-testid={`classes__class-${row.getValue("id")}__show`}>{t("view")}</Button>
                      </a>
                    </Link>
                  </div>
                ),
              }),
            ]}
          />
        )}
      </Block>

      {canViewAssessmentRounds && (
        <Block className={styles.TeacherDashboardRatings}>
          <BlockTitle>{t("sent_ratings")}</BlockTitle>
          <PaginationContextProvider value={paginationData}>
            <EventList>
              {assessmentRounds?.data?.map((assessmentRound) => {
                let warningText = "";

                if (new Date(assessmentRound.valid_to).getTime() < new Date().getTime()) {
                  warningText = t("expired");
                } else if (new Date(assessmentRound.valid_to).getTime() < assessmentRoundThresholdDate.getTime()) {
                  warningText = t("time_left_until_deadLine", {
                    timeLeft: formatDateDistance(assessmentRound.valid_to, false),
                  });
                }

                return (
                  <Link
                    href={`/classes/${assessmentRound.class.id ?? 1}/rate?assessmentRoundId=${assessmentRound.id}`}
                    passHref
                    key={assessmentRound.id}
                  >
                    <a>
                      <EventListItem
                        name={assessmentRound.name}
                        date={assessmentRound.valid_to}
                        dateWarning={warningText}
                        subText={`${assessmentRound.assessed_count}/${assessmentRound.student_count} ${t("submitted")}`}
                      />
                    </a>
                  </Link>
                );
              })}
              {assessmentRounds?.data?.length === 0 && <>{t("no_open_ratings")}</>}
            </EventList>
            <Pagination onClick={handlePagination} />
          </PaginationContextProvider>
        </Block>
      )}
    </div>
  );
};
