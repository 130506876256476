import styles from "./Spinner.module.scss";
import { Spinner } from "./Spinner";

export const SpinnerSection = () => {
  return (
    <div className={styles.spinnerContainer}>
      <Spinner />
    </div>
  );
};
